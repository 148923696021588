import "../css/styles/reset.scss";
import "./App.scss";
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import ContentGrid from "./components/ContentGrid/ContentGrid";
import TheProLab from "./components/TheProLab/TheProLab";
import ScrollToTop from "./components/ScrollToTop";
import About from "./components/About/About";
import AlienwareTrainingFacility from "./components/AlienwareTrainingFacility/AlienwareTrainingFacility";
import { Route, Routes } from "react-router-dom";
import TechSpecs from "./components/TechSpecs/TechSpecs";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Nav />
              <AlienwareTrainingFacility />
              <About />
              <TechSpecs />
              <TheProLab />
              <ContentGrid />
              <Footer />
            </div>
          }
        ></Route>
      </Routes>
    </>
  );
}

export default App;
