/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./TheProLab.scss";
import { BsArrowRight } from "react-icons/bs";
import Heading from "../Heading/Heading";
import alienwarelogo from "../../../Logos/AlienwareLogo.svg";
import TLLogo from "../../../Logos/TeamLiquidLogo.svg";
import prolabcontentimage from "../../../images/theprolab.png";
import elementsFirstImage from "./../../../images/Photoshoots/3.jpg";
import elementsSecondImage from "./../../../images/Photoshoots/5.jpg";
import elementsThirdImage from "./../../../images/Photoshoots/9.jpg";
import elementsFourthImage from "./../../../images/Photoshoots/8.jpg";

function TheProLab() {
  return (
    <div className="prolabSection" id="prolab">
      <div className="prolabContent">
        <div className="prolabContentImagePart">
          <img src={prolabcontentimage} alt="prolabcontentimage" />
        </div>
        <div className="prolabContentPart">
          <img
            style={{
              width: "30px",
              height: "42px",
              marginTop: 16,
              marginBottom: 16,
              filter: "brightness(0) invert(1)",
            }}
            src={alienwarelogo}
          ></img>
          <Heading level="h2" type="subheadingWhite" title="THE PRO LAB" />
          <p>
            The Pro Lab is the new innovation to come out of the Alienware &
            Team Liquid partnership. This is a dedicated, collaborative and
            multi-year project and investment from both organizations, with the
            goal of setting a new standard for how esports athletes train.
          </p>
          <p>
            Each of our Alienware Training Facility locations (currently Los
            Angeles & Utrecht, but also future locations) will have a physical
            The Pro Lab space, custom built for our performance scientists to
            have the newest Alienware equipment and state-of-the-art technology
            available to them. This space will be utilized by all of our Team
            Liquid athletes, as well as other esports and traditional sport
            athletes to further advance the cognitive performance of high
            performers.
          </p>
          <a href="https://tl.gg/theprolab" target="_blank" rel="noopener">
            <div className="learnMoreAbout">
              <p>
                Learn more about The Pro Lab
                {<BsArrowRight style={{ marginTop: 5, marginLeft: 5 }} />}
              </p>
            </div>
          </a>
        </div>
      </div>
      <div className="theprolabfeatures">
        <h1 className="prolabHeaderH1">
          ALIENWARE TRAINING FACILITY TECH SPECS
        </h1>
        <div className="featuredItems">
          <div className="featuredItem">
            <img
              src={elementsFirstImage}
              style={{ width: "100%", display: "flex", margin: "auto" }}
            ></img>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Heading
                level="h2"
                type="subheading"
                title="Fiber-Optic Internet"
              />
            </div>
            <p>
              5 Gbps of redundant fiber-optic internet Routing, security and
              firewall powered by Dell PowerEdge R350 server with dual 40 Gbps
              and 10 Gbps uplinks for future expansion
            </p>
            <p>
              100 Gbps fiber optic internal network powered by Dell PowerSwitch
              models S4112T-ON and S3148P
            </p>
            <p>Mix of 1, 10 and 40 Gbps connections to end user workstations</p>
            <p>
              High speed 802.11ax (Wi-Fi 6) wireless network for laptop users
            </p>
          </div>
          <div className="featuredItem">
            <img
              src={elementsThirdImage}
              style={{ width: "100%", display: "flex", margin: "auto" }}
            ></img>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Heading level="h2" type="subheading" title="The Pro Lab" />
            </div>
            <p>Top of the line Aurora R13 & R14s</p>
            <p>35 AW2721D with docks for employees</p>
            <p>30 AW2521H displays for players</p>
            <p>Storage of 100+70 TB for 1UP, 182 TB for LCS</p>
            <p>Powered by Dell PowerEdge R740xd2 with dual 25 Gbps uplinks</p>
            <p>Full Google Meet suite for all conference rooms</p>
          </div>
          <div className="featuredItem">
            <img
              src={elementsSecondImage}
              style={{ width: "100%", display: "flex", margin: "auto" }}
            ></img>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Heading
                level="h2"
                type="subheading"
                title="Verizon Streaming Pods"
              />
            </div>
            <p>Top of the line Alienware Aurora R13s</p>
            <p>1x Alienware 34 Inch Curved QD-OLED Gaming Monitor</p>
            <p>1x Alienware 27 Inch QHD Gaming Monitor</p>
            <p>Sony A7 Cameras with Sigma Art 24-70mm Lens</p>
            <p>Elgato Stream Deck, Capture cards, Wave XLR</p>
            <p>Shure SM7B Microphones</p>
          </div>
          <div className="featuredItem">
            <img
              src={elementsFourthImage}
              style={{ width: "100%", display: "flex", margin: "auto" }}
            ></img>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Heading
                level="h2"
                type="subheading"
                title="Modern Hybrid Workspace"
              />
            </div>
            <p>50% more space, from 10,000 sq ft to 15,000 sq ft.</p>
            <p>Focused on the modern workplace for hybrid employees</p>
            <p>Plenty of areas for collaboration and teamwork</p>
          </div>
        </div>
      </div>
      <div className="prolabtlImageSection">
        <div className="prolabtlImage">
          <img src={TLLogo}></img>
        </div>
      </div>
    </div>
  );
}

export default TheProLab;
