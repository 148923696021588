/* eslint-disable react/jsx-no-target-blank */
import "./Nav.scss";
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import AWTFLogo from "../../../Logos/AWTFLogoNew.svg";
import tlxawlogo from "../../../Logos/TLxAW.svg";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="nav">
      <div className="container">
        <div className="mobileLogo">
          <img src={AWTFLogo} style={{ width: 250 }} alt="" />
        </div>
        <button
          onClick={handleMenuToggle}
          className={`nav__menu-button ${isOpen ? "is--active" : null}`}
          data-component="nav-menu-button"
          type="button"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={`nav__list ${isOpen ? "is--open" : "is--closed"}`}>
          <div className="awtflogonav">
            <Link
              to={process.env.PUBLIC_URL}
              onClick={() => window.scrollTo(0, 0)}
            >
              <img src={AWTFLogo} style={{ width: 250 }} alt="" />
            </Link>
          </div>
          <li>
            <Link
              className="nav__list-link"
              to={process.env.PUBLIC_URL + "/#about"}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className="nav__list-link"
              to={process.env.PUBLIC_URL + "/#techspecs"}
            >
              Tech Specs
            </Link>
          </li>
          <li>
            <Link
              className="nav__list-link"
              to={process.env.PUBLIC_URL + "/#prolab"}
            >
              Pro Lab
            </Link>
          </li>
          <li>
            <Link
              className="nav__list-link"
              to={process.env.PUBLIC_URL + "/#gallery"}
            >
              Gallery
            </Link>
          </li>
          <li>
            <Link
              className="nav__list-link"
              to={process.env.PUBLIC_URL + "/#contact"}
            >
              Contact
            </Link>
          </li>
          <li>
            <a
              className="nav__list-link"
              href="https://www.dropbox.com/sh/fypetu64f1v84xw/AAA9xKsQAMvdJpKbi1vkHb5pa?dl=0"
              target="_blank"
              rel="noopener"
            >
              Press
            </a>
          </li>
          <div className="tllogonav">
            <a
              href="https://www.teamliquid.com/"
              target="_blank"
              rel="noopener"
            >
              <div className="tlxaw">
                <img
                  src={tlxawlogo}
                  alt=""
                  style={{
                    height: "50px",
                  }}
                ></img>
              </div>
            </a>
          </div>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
