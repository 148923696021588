import React, { useEffect } from "react";
import "./TechSpecs.scss";

function TechSpecs() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const square = entry.target.querySelector(".facilityStatBig");
        const squareTwo = entry.target.querySelector(".facilityStatBigTwo");
        const squareThree = entry.target.querySelector(".facilityStatBigThree");
        const squareFour = entry.target.querySelector(".facilityStatBigFour");

        if (entry.isIntersecting) {
          square.classList.add("myAnimation");
          squareTwo.classList.add("myAnimation");
          squareThree.classList.add("myAnimation");
          squareFour.classList.add("myAnimation");
          return; // if we added the class, exit the function
        }

        // We're not intersecting, so remove the class!
        square.classList.remove("myAnimation");
        squareTwo.classList.remove("myAnimation");
        squareThree.classList.remove("myAnimation");
        squareFour.classList.remove("myAnimation");
      });
    });

    observer.observe(document.querySelector(".facilityStat"));
  });

  return (
    <div className="facilityInfo" id="techspecs">
      <div className="facilityTour">
        <iframe
          src="https://player.vimeo.com/video/740074111?h=b3d770803b"
          title="AWTF NA Facility Tour"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="facilityStat">
        <div className="facilityStatDiv">
          <div className="facilityStatBig">15,000 SQUARE FEET</div>
          <div className="facilityStatSmall">OF MODERN HYBRID WORKSPACE</div>
        </div>
        <div className="facilityStatDiv">
          <div className="facilityStatBigTwo">65 CHAMPIONSHIPS</div>
          <div className="facilityStatSmall">IN PREMIER AND MAJOR TITLES</div>
        </div>
        <div className="facilityStatDiv">
          <div className="facilityStatBigThree">
            1ST ESPORTS TRAINING FACILITY
          </div>
          <div className="facilityStatSmall">OF ITS KIND IN THE WORLD</div>
        </div>
        <div className="facilityStatDiv">
          <div className="facilityStatBigFour">25+ COUNTRIES</div>
          <div className="facilityStatSmall">REPRESENTED AT TEAM LIQUID</div>
        </div>
      </div>
    </div>
  );
}

export default TechSpecs;
