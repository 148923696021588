import "./Social.scss";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

function Social() {
  return (
    <ul className="social-media">
      <li className="social-media__link">
        <a
          className="social-media__link"
          href="https://www.facebook.com/teamliquid"
        >
          <FaFacebook style={{ fontSize: 30 }} />
        </a>
      </li>
      <li className="social-media__link">
        <a className="social-media__link" href="https://twitter.com/teamliquid">
          <FaTwitter style={{ fontSize: 30 }} />
        </a>
      </li>
      <li className="social-media__link">
        <a
          className="social-media__link"
          href="https://www.instagram.com/teamliquid/"
        >
          <FaInstagram style={{ fontSize: 30 }} />
        </a>
      </li>
    </ul>
  );
}

export default Social;
