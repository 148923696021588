import React from "react";
import "./AlienwareTrainingFacility.scss";
import { Button } from "antd";
import "antd/dist/antd.min.css";
import heroVideo from "./hero5.mp4";
import heroImage from "./hero5.jpg";

function AlienwareTrainingFacility() {
  return (
    <div className="openingImage">
      <video
        autoPlay
        loop
        muted="true"
        playsInline
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          objectFit: "cover",
          zIndex: "-1",
        }}
        poster={heroImage}
      >
        <source src={heroVideo} type="video/mp4" />
      </video>
      <div className="videoCarousel">
        <h1 className="videoCarouselH1">The New Alienware Training Facility</h1>
        <p className="videoCarouselP">
          Los Angeles <br /> California, United States
        </p>
        <Button
          className="readMoreButton"
          type="default"
          href="#about"
          style={{ fontWeight: "bold" }}
        >
          READ MORE
        </Button>
      </div>
    </div>
  );
}

export default AlienwareTrainingFacility;
