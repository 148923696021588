import "./Footer.scss";
import Social from "../Social/Social";
import Heading from "../Heading/Heading";
import { AiOutlineRightCircle } from "react-icons/ai";

function Footer() {
  return (
    <footer id="contact" className="footer section">
      <div className="container">
        <Heading level="h2" type="heading" title="Contact" />
        <div className="footer__contact-options">
          <address className="footer__pr">
            <Heading
              level="h3"
              type="subheadingWhite"
              title="For all inquiries"
            />
            <a className="footer__button" href="mailto:pr@teamliquid.com">
              PR@teamliquid.com
              <AiOutlineRightCircle />
            </a>
          </address>
          <div className="footer__press">
            <Heading level="h3" type="subheadingWhite" title="Press" />
            <a
              href="https://www.dropbox.com/sh/fypetu64f1v84xw/AAA9xKsQAMvdJpKbi1vkHb5pa?dl=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer__button">
                Download Press Assets
                <AiOutlineRightCircle />
              </div>
            </a>
          </div>
        </div>
        <div className="footer__bottom">
          <Social />
          <span>© Alienware & Team Liquid</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
