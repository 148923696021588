/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./About.scss";
import Heading from "../Heading/Heading";
import TLLogo from "../../../Logos/TeamLiquidLogo.svg";

function About() {
  return (
    <div className="aboutSection" id="about">
      <div className="prolabtlImage">
        <img src={TLLogo} alt=""></img>
      </div>
      <Heading level="h2" type="subheading" title="An Upgrade Well Deserved" />
      <div className="aboutContent">
        <p>
          Team Liquid is known in the esports space as the innovators of the
          industry. With a community full of passionate fans and a team of
          professionals working to bring the best esports experiences to the
          world, It's only fair that they get the tools they need to be
          successful.
        </p>
        <p>
          The launch of the Alienware Training Facility (AWTF NA) in 2017
          brought advancements to the competitive performance of Team Liquid
          players, and housed a team of highly skilled and talented
          professionals who worked together to redefine the corporate workspace.
        </p>
        <p>
          As time went on, the organization had scaled more than expected, and
          there was a need to reflect that in the facility. It was time for an
          upgrade, one that would go beyond our vision of creating the best
          esports facilities in the world. For our players. For our teams. For
          the win.
        </p>
        <p>Welcome to the new AWTF NA.</p>
      </div>
    </div>
  );
}

export default About;
