import "./ContentGrid.scss";

function ContentGrid() {
  return (
    <section id="gallery">
      <div>
        <div className="galleryImages">
          <div className="galleryTopImage"></div>
          <div className="galleryMiddleSector">
            <div className="galleryMiddleSectorLeft"></div>
            <div className="galleryMiddleSectorMiddle">
              <div className="galleryMiddleSectorMiddleTop"></div>
              <div className="galleryMiddleSectorMiddleBottom"></div>
            </div>
            <div className="galleryMiddleSectorRight">
              <div className="galleryMiddleSectorRightTop"></div>
              <div className="galleryMiddleSectorRightBottom"></div>
            </div>
          </div>
          <div className="galleryBottomImage"></div>
        </div>
      </div>
    </section>
  );
}

export default ContentGrid;
